<template>
  <b-modal
    id="modal-from-banco"
    v-model="modal"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label="* DESCRIPCION"
              label-for="descripcion"
            >
              <validation-provider
                #default="{ errors }"
                name="Descripcion"
                rules="required|min:2"
              >
                <b-form-input
                  id="descripcion"
                  v-model="form.descripcion"
                  @keyup.enter="validar()"
                  disabled
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.descripcion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row class="mb-0-5">
          <b-col cols="12" class="mb-1">
            <b-form-checkbox v-model="estado" name="check-button" switch inline>
              ACTIVO
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import TipoDocumentoService from "../services/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

export default {
  name: "ModalFormTipoDocumento",
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    BFormCheckbox,
  },
  props: ["item", "tipo"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        descripcion: "",
        estado: 1,
      },
      estado: true,
      required,
      submitted: false,
      errores: [],
      modal: false,
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR TIPO DOCUMENTO" : "NUEVO TIPO DOCUMENTO";
    },
  },
  watch: {
    "form.descripcion": function () {
      this.errores.descripcion = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.form.estado = this.estado ? 1 : 0;
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.descripcion = "";
          this.form.estado = 1;
          this.estado = true;
          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.descripcion = data["descripcion"];
          this.form.estado = data["estado"];
          this.estado = data["estado"] == 1 ? true : false;
          break;
        }
      }

      this.modal = true;
      this.errores = [];

      setTimeout(() => {
        this.$nextTick(() => {
          document.getElementById("descripcion").focus();
        });
      }, 100);
    },
    cerrarModal() {
      this.form.id = null;
      this.modal = false;
    },
    actualizarItem(item) {
      this.form.id = null;
      this.modal = false;
      this.$emit("actualizarItem", item);
      this.submitted = false;
    },
    // SERVICES
    async registrar() {
      try {
        const response = await TipoDocumentoService.store(this.form);
        this.actualizarItem(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        const response = await TipoDocumentoService.update(
          this.form.id,
          this.form
        );
        this.actualizarItem(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
  },
};
</script>